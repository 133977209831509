body {
	margin: 0;
	padding: 0;
	font-family: "Poppins", sans-serif;
	background-color: #fcfcfc;
}
p {
	font-weight: normal;
	margin: 0;
	font-family: "Fraunces", serif;
}
li {
	font-family: "Fraunces", serif;
}
a {
	font-family: "Fraunces", serif;
}
span {
	font-family: "Fraunces", serif;
}
ul li {
	list-style: none;
}
.header-menu,
.header-underline-items,
.header {
	background-color: #111111;
	padding: 1px 0;
}
.header-underline-items {
	max-width: 1100px;
	margin: 0 auto;
	display: flex;
	justify-content: end;
	border-bottom: 1px solid #ffffff;
}
.header-menu .header-menu-ul {
	display: flex;
	justify-content: end;
	margin: 0;
	padding: 3px 0;
	color: #ffffff;
}
.header-menu .header-menu-ul .header-menu-li {
	list-style: none;
	text-decoration: none;
	display: inline-flex;
	align-items: center;
	text-align: center;
	color: #ffffff;
	text-decoration: none;
	margin-left: 20px;
	font-size: 14px;
}
.header-menu .header-menu-ul .header-menu-li span {
	margin-right: 4px;
}
.logo img {
	width: 150px;
	z-index: 999;
	position: relative;
}
.header {
	display: flex;
	justify-content: space-between;
	left: 0;
	right: 0;
	max-width: 1100px;
	margin: 0 auto;

	z-index: 3;
}
.header-cont {
	margin-top: 4px;
	width: 100%;
	background-color: #111111;
}

.header .logo {
	display: block;
	float: left;
	text-decoration: none;
	font-size: x-small;
	margin-bottom: 0;
}

.private-cabinet {
	margin-top: auto;
	display: flex;
	align-items: end;
	padding-bottom: 6px;
}
.private-cabinet p,
.footer-info p {
	color: white;
	display: flex;
}

.privat-cab-text,
.footer-inf-txt {
	margin-left: 10px;
	line-height: 23px;
}
.private-cabinet a,
.footer-info a {
	text-decoration: none;
}

/* .private-cabinet a:hover p,
 .footer-info a:hover p {
	color: #d52b1e;
	transition: all 0.3s;
 } */
.navbar input[type="checkbox"],
.navbar .hamburger-lines {
	display: none;
}

.navbar {
	width: 100%;
	z-index: 12;
}

.navbar-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 !important;
}

.menu-items {
	display: flex;
	margin-top: auto;
	padding-bottom: 5px;
	margin-bottom: 0;
	padding-left: 0;
}

.menu-items li {
	list-style: none;
	text-decoration: none;
	display: inline-flex;
	align-items: center;
	text-align: center;
	color: #ffffff;
	text-decoration: none;
	margin-left: 14px;
	padding: 0 8px 3px 8px;
	border-bottom: 2px solid transparent;
}
.header .menu-items li:hover {
	/* color: #d52b1e; */
	transition: all 0.2s;
	border-bottom: 2px solid #d52b1e;
}

.menu-items a {
	text-decoration: none;
	color: #444;
	font-weight: 500;
	transition: color 0.3s ease-in-out;
}

.menu-items a:hover {
	color: #117964;
	transition: color 0.3s ease-in-out;
}

.logo {
	font-size: 2.3rem;
	margin-bottom: 4px;
}

.menu-items li.header-menu-li-mob {
	display: none;
}
li.header-menu-li .material-symbols-outlined {
	font-size: 20px;
}
.menu-items .header-men-li-active {
	border-bottom: 2px solid #d52b1e !important;
}

/* dropdown css */
/* .dropbtn {
	background-color: #4CAF50;
	color: white;
	padding: 16px;
	font-size: 16px;
	border: none;
	cursor: pointer;
 }
 
 .dropdown {
	position: relative;
	display: inline-block;
 }
 
 .dropdown-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 1;
 }
 
 .dropdown-content a {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
 }
 
 .dropdown-content a:hover {background-color: #f1f1f1}
 
 .dropdown:hover .dropdown-content {
	display: block;
 }
 
 .dropdown:hover .dropbtn {
	background-color: #3e8e41;
 } */
/* dropdown css */
.private-cabinet-mob {
	display: none;
}
.header-menu-li .css-vubbuv {
	font-size: 20px;
	margin-right: 4px;
}
