body {
	margin: 0;
	padding: 0;
	font-family: "Poppins", sans-serif;
	background-color: #fcfcfc;
}
p {
	font-weight: normal;
	margin: 0;
}
ul li {
	list-style: none;
}
/* .front-space {
	} */
.container {
	max-width: 1100px;
	margin: 0 auto;
	padding: 0 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* height: 100%; */
}
footer {
	height: fit-content;
	background-color: #111111;
	/* position: relative; */
	position: fixed;
	bottom: 0;
	width: 100%;
}
.footer-logo img {
	width: 100px;
}
.footer-logo {
	/* margin-left: 50px; */
	margin-top: auto;
	margin-bottom: auto;
}

/* .private-cabinet p {
	margin: revert;
 } */
.nav-elements {
	margin-top: auto;
}
.nav-elements ul {
	display: flex;
	justify-content: space-between;
	list-style-type: none;
	align-items: start;
	padding-left: 0;
}

/* .nav-elements ul li:not(:last-child) {
	margin-right: 45px;
 } */

.nav-elements ul li {
	font-size: 16px;
	font-weight: 400;
	color: white;
	text-decoration: none;
}

.nav-elements ul a:hover {
	color: #d52b1e;
	transition: all 0.3s;
}

.nav-elements ul a.active {
	color: #d52b1e;
	font-weight: 400;
	position: relative;
}
.footer-nav-ul {
	margin-top: 0;
	flex-wrap: wrap;
	margin-bottom: 0;
}
.footer-nav-ul li {
	/* width: 20%; */
	/* margin-right: 0 !important; */
	margin-top: 11px;
	margin-bottom: 11px;
}
.info-box {
	display: flex;
}
.footer-info {
	margin-left: 50px;
	display: grid;
}
.private-cabinet p,
.footer-info p {
	color: white;
	display: flex;
}
.private-cabinet a,
.footer-info a {
	text-decoration: none;
	/* margin: 5px 0; */
}

/* .private-cabinet a:hover p,
 .footer-info a:hover p {
	color: #d52b1e;
	transition: all 0.3s;
 } */
.privat-cab-text,
.footer-inf-txt {
	margin-left: 10px;
	line-height: 23px;
}
.footer-info li:first-child {
	margin: 0;
}
.footer-info li {
	/* margin-top: 18px; */
	margin-top: 15px;
	margin-bottom: 15px;
}
.footer-big-logo {
	display: none;
}
.footer-container {
	display: block;
}
.footer-container .nav-elements {
	border-bottom: 1px solid white;
}
.footer-container .nav-elements2,
.footer-container .nav-elements3 {
	border-bottom: none;
}
.footer-container2 {
	display: flex;
}
.footer-nav-ul2 li span {
	font-size: 20px;
	margin-right: 4px;
}
.nav-elements2 ul li {
	font-size: 14px;
	display: inline-flex;
	align-items: center;
	margin-left: 10px;
	margin-right: 10px;
}
.nav-elements3 ul li {
	font-size: 14px;
}
.footer-container .nav-elements3 {
	margin-top: auto 0;
}
