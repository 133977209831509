html,
body {
	overflow-x: hidden;
}
@media (max-width: 1150px) {
	.header-underline-items {
		margin: 0 15px;
	}
	.private-cabinet {
		margin-right: 15px;
	}
	.logo {
		margin-left: 15px;
	}
}
@media (max-width: 1000px) {
	.cont-row {
		margin: 50px 0 150px 0;
		justify-content: center;
	}
	.distance-right {
		margin-left: 0;
	}
	.footer-info {
		margin-left: 0px;
	}
	/* header */
	.menu-items li {
		margin-bottom: 20px;
	}
	.private-cabinet {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 100px;
	}
	.navbar-container input[type="checkbox"],
	.navbar-container .hamburger-lines {
		display: block;
	}

	.navbar-container {
		display: block;
		position: relative;
		height: 70px;
	}
	.private-cabinet {
		align-items: center;
		padding-bottom: 0;
	}
	.navbar-container input[type="checkbox"] {
		position: absolute;
		display: block;
		height: 25px;
		width: 30px;
		top: 0;
		bottom: 0;
		margin: auto;
		right: 20px;
		z-index: 999;
		opacity: 0;
	}

	.navbar-container .hamburger-lines {
		display: block;
		height: 19px;
		width: 26px;
		position: absolute;
		margin: auto;
		top: 0;
		bottom: 0;
		align-items: center;
		right: 20px;
		z-index: 991;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.navbar-container .hamburger-lines .line {
		display: block;
		height: 3px;
		width: 100%;
		border-radius: 10px;
		background: white;
	}

	.navbar-container .hamburger-lines .line1 {
		transform-origin: 0% 0%;
		transition: transform 0.4s ease-in-out;
	}

	.navbar-container .hamburger-lines .line2 {
		transition: transform 0.2s ease-in-out;
	}

	.navbar-container .hamburger-lines .line3 {
		transform-origin: 0% 100%;
		transition: transform 0.4s ease-in-out;
	}

	.navbar .menu-items {
		margin-top: 0;
		padding-top: 100px;
		background: #111111;
		height: calc(100vh - 120px);
		max-width: 300px;
		display: flex;
		flex-direction: column;
		margin-left: -40px;
		padding-left: 0px;
		transition: transform 0.5s ease-in-out;
		position: absolute;
		width: 300px;
		right: -400px;
	}

	.logo {
		top: 5px;
		right: 15px;
		font-size: 2rem;
	}

	.navbar-container input[type="checkbox"]:checked ~ .menu-items {
		transform: translateX(0%);
		position: absolute;
		width: 300px;
		right: -20px;
		top: 0;
	}

	.navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
		transform: rotate(35deg);
		transform: rotate(45deg);
		width: 20px;
	}

	.navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
		transform: scaleY(0);
	}

	.navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
		/* transform: rotate(-35deg); */
		transform: rotate(-45deg);
		width: 20px;
		margin-bottom: 3.5px;
	}
	.header .menu-items li:hover,
	.menu-items .header-men-li-active {
		width: fit-content;
	}
	/* end header */
}
@media (max-width: 768px) {
	.header-menu-li span {
		margin-right: 10px;
	}
	.nav-elements ul li:not(:last-child) {
		margin-right: 30px;
	}
	.distance-right {
		width: 41%;
	}
	.image-box-items {
		margin: auto 0;
	}
	.cont-row {
		margin: 50px 0;
		justify-content: center;
	}
	.nav-elements ul {
		margin-bottom: 0px;
		align-items: flex-start;
		flex-direction: column;
	}
	.distance-right {
		width: 50%;
	}
	.private-cab-h {
		font-size: 24px;
	}
	.mob-p,
	.email-p {
		font-size: 16px;
	}
	.mob-email-input {
		height: 40px;
		margin-bottom: 0;
	}
	.private-cab-bottom-txt {
		font-size: 14px;
	}
	.footer {
		position: relative;
		/* padding-top: 35px; */
	}
	.footer-info li:first-child {
		margin-bottom: 10px;
	}
	.footer-info li {
		margin-top: 10px;
		margin-bottom: 0;
	}
	.footer .footer-container {
		/* display: flex; */
		flex-direction: column;
		padding-bottom: 11px;
	}
	.footer-nav-ul {
		padding: 0;
	}
	.footer-nav-ul li {
		margin-top: 15px;
	}
	.footer-nav-ul li a {
		font-size: 16px;
	}
	.footer-container .nav-elements {
		padding-top: 5px;
		margin-right: auto;
	}

	.info-box {
		justify-content: space-between;
		flex-direction: row-reverse;
		margin-right: auto;
		display: contents;
	}
	.footer-info {
		padding: 0;
		margin-left: 0;
		margin-right: auto;
	}
	.footer-logo {
		margin-left: 0;
		margin-right: auto;
		/* position: absolute; */
		bottom: 35px;
		right: 15px;
	}

	.footer-inf-txt {
		font-size: 14px;
	}
	.footer-info a p {
		align-items: center;
	}
	/* .footer-logo img {
    width: 35px;
  } */
	.nav-elements ul li {
		width: 100%;
	}
	.nav-elements2 ul.footer-nav-ul2 li {
		margin-left: 0;
		margin-right: 0;
	}
	.footer-container .nav-elements2 {
		padding-top: 11px;
	}
	.footer-container .nav-elements3 {
		padding-top: 30px;
		margin: 0 auto 0 0;
	}
}
@media (max-width: 550px) {
	.menu-items li.header-menu-li-mob {
		display: inline-flex;
		margin-top: 20px;
		margin-bottom: 0 !important;
		text-align: left;
	}
	.header-underline-items {
		display: none;
	}
}
@media (max-width: 530px) {
	.cont-row {
		flex-direction: column-reverse;
	}
	.distance-right,
	.image-box-items {
		margin: 0 auto;
		width: 65%;
	}
	.header-menu ul {
		flex-wrap: wrap;
	}
}
@media (max-width: 500px) {
	.navbar .menu-items {
		padding-left: 0px;
	}
	.header-menu .header-menu-ul .header-menu-li {
		margin: auto;
		width: 50%;
	}
	.header-menu-li:first-child {
		margin-top: 50px;
	}
	.private-cabinet-mob {
		display: block;
		position: relative;
		right: 0;
	}
	.private-cabinet-desk {
		display: none;
	}
	.menu-items li {
		margin-top: 10px;
		margin-bottom: 10px;
	}
	.menu-items li.header-menu-li:nth-last-child(n + 4) {
		margin-top: 50px;
	}
}
@media (max-width: 400px) {
	.distance-right,
	.image-box-items {
		width: 90%;
	}
	.footer-nav-ul li a {
		font-size: 12px;
	}
	.footer-nav-ul li {
		margin-top: 5px;
	}
}
